<template>
  <stepBar
    :labels="labels"
    :currentStep="step"
    :totalSteps="labels.length"
  />
</template>

<script>

const stepBar = () => import('@/components/layout/stepBar')

export default {
  name: 'macroStepper',
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    stepBar
  },
  data: function () {
    return {
      labels: [
        "Situation juridique des parties",
        "Concernant l'entreprise cédée",
        'Modalités de paiement du prix​',
      ],
    }
  },
}
</script>
